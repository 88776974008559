import { Box, HStack, Text } from '@chakra-ui/react';
import { css } from '@emotion/css';
import { IconType } from 'react-icons/lib';

type LinkTileProps = { icon: IconType; label: string };

const LinkTile = ({ icon: Icon, label }: LinkTileProps) => (
  <Box
    width="sm"
    maxWidth="100%"
    borderWidth="1px"
    borderRadius="lg"
    p={4}
    className={css`
      transition: transform 0.15s ease-in-out;
    `}
    _hover={{ bg: 'gray.50', transform: 'translateY(-2px)' }}
  >
    <HStack spacing={4}>
      <Icon size={36} />
      <Text fontSize="2xl">{label}</Text>
    </HStack>
  </Box>
);

export default LinkTile;

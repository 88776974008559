import type { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { Center, VStack } from '@chakra-ui/react';
import { FiEdit3, FiAperture, FiArchive } from 'react-icons/fi';

import LinkTile from '@/components/ui/LinkTile';

const HomePage: NextPage = () => (
  <>
    <Head>
      <title>Royal Rangers Sponsorenlauf</title>
    </Head>

    <Center height="100%">
      <VStack spacing={4}>
        <Link href="/sponsoren-anmeldung">
          <LinkTile icon={FiEdit3} label="Sponsoren Anmeldung" />
        </Link>

        <Link href="/scanner">
          <LinkTile icon={FiAperture} label="Scanner" />
        </Link>

        <Link href="/verwaltung">
          <LinkTile icon={FiArchive} label="Verwaltung" />
        </Link>
      </VStack>
    </Center>
  </>
);

export default HomePage;
